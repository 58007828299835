import React, { useEffect, Suspense } from "react";
import { lazy } from "@loadable/component";
import { Link, graphql } from "gatsby";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3";
import Layout from "../components/layout";
import PhotoGalleryComponent from "../components/photo-gallery-component";
import { Star, X } from "react-feather";
import SEO from "../components/seo";

import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Logo from "../components/logo-svg";
import { ReturnSourceFillColor } from "../helpers/reviewSources";
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from "../consts";
import TimeAgo from "react-timeago";
import WhyUs from "../components/whyus";
import HeroRatingStars from "../components/hero-rating-stars";

const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: ${(props) => props.fillColor || "#ffbc00"};
    stroke: none;
  }
`;

const AllReviewsContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
  z-index: 10000;

  width: 55vw;
  left: 22.5vw;
  top: 5vh;
  height: 90vh;
  overflow: hidden;
  border-radius: 0.8em;
  @media (max-width: 992px) {
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 0;
  }
  border-bottom: 1px solid #eee;

  .header-reviews-modal {
    background: #fff;
    position: sticky;
    top: 0;
  }
  .inner-reviews-container {
    height: 100%;
    overflow-y: scroll;
  }
  .logo-container {
    width: 140px;
  }

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    pointer-events: inherit;
  }
`;

const Stars = ({ size, fillColor }) => (
  <StarsContainer fillColor={fillColor ? fillColor : 'green'}>
    <Star size={size ? size : 25} />
    <Star size={size ? size : 25} />
    <Star size={size ? size : 25} />
    <Star size={size ? size : 25} />
    <Star size={size ? size : 25} />
  </StarsContainer>
);

const ReviewSource = styled.span`
  font-weight: normal;
  color: #444;
  display: flex;
  align-items: center;
`;

const ReviewSourceComponent = (props) => {
  let { source } = props;
  if (source == "homeadvisor")
    return (
      <ReviewSource>
        {" "}
        <img
          style={{ width: "40px", marginRight: "7px" }}
          src={"/assets/homeadvisor.svg"}
          alt="HomeAdvisor 5 Star Rating"
        />
      </ReviewSource>
    );
  if (source == "yelp")
    return (
      <ReviewSource>
        {" "}
        <img style={{ width: "40px" }} src={"/assets/yelp.svg"} alt="Yelp 5 Star Rating" />
      </ReviewSource>
    );
  if (source == "guildquality")
    return (
      <ReviewSource>
        {" "}
        <img
          style={{ width: "35px", marginRight: "7px" }}
          src={"/assets/guildquality.svg"}
          alt="GuildQuality 5 Star Rating"
        />
      </ReviewSource>
    );
  if (source == "houzz")
    return (
      <ReviewSource>
        {" "}
        <img style={{ width: "40px" }} src={"/assets/houzz.svg"} alt="Houzz Rating" />
      </ReviewSource>
    );
  if (source == "angieslist")
    return (
      <ReviewSource>
        {" "}
        <img
          style={{ width: "35px", marginRight: "7px" }}
          src={"/assets/angieslist.svg"}
          alt="Angie's List A+ Rating"
        />
      </ReviewSource>
    );
  if (source == "facebook")
    return (
      <ReviewSource>
        {" "}
        <img style={{ width: "40px" }} src={"/assets/facebook.svg"} alt="Facebook A+ Rating" />
      </ReviewSource>
    );
  
  if (source == "google")
    return (
      <ReviewSource>
        <img style={{ width: "40px" }} src={"/assets/google.svg"} alt="Google 5 Star Rating" />
      </ReviewSource>
    );
  else return <ReviewSource>{source}</ReviewSource>;
};

const ReviewForInModal = ({ item, itemKey }) => {
  return (
    <div className={`p-4 border-b ${itemKey % 2 ? "" : "bg-white "}`}>
      <div className="flex items-center justify-center">
        {item.node.userPhoto ? (
          <GatsbyImage
            className="user-reviewer-photo rounded-full"
            style={{ width: "45px", height: "45px" }}
            image={getImage(item.node.userPhoto)}
          />
        ) : (
          <img
            className="user-reviewer-photo rounded-full"
            style={{ width: "45px", height: "45px" }}
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+ZGlzYWJsZWQtLWludmVydGVkPC90aXRsZT48ZyBmaWxsPSIjQUFCN0M0IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgZmlsbC1vcGFjaXR5PSIuMiIgY3g9IjEwIiBjeT0iMTAiIHI9IjEwIi8+PHBhdGggZD0iTTEwIDIwYTkuOTY0IDkuOTY0IDAgMCAxLTcuMjUtMy4xMjVjMS4wNjMtMS45NSAzLjMwNi0zLjI2MiA3LjI1LTMuMjYyczYuMTg4IDEuMzEyIDcuMjUgMy4yNjJBOS45NjUgOS45NjUgMCAwIDEgMTAgMjB6bS4wMDQtMTYuMjMxbC4wMDEtLjAwMmMyLjA3NyAwIDMuNzU5IDEuNTA3IDMuNzU5IDMuOTU3cy0xLjY4MyAzLjk2Mi0zLjc2IDMuOTYyYy0yLjA3NiAwLTMuNzYtMS41MDgtMy43Ni0zLjk1OHMxLjY4NS0zLjk2IDMuNzYtMy45NnoiLz48L2c+PC9zdmc+"
          />
        )}
        <div className="flex justify-between w-full">
          <div>
            <div className="flex items-center img-zero-child">
              <h4 className="mb-0 font-display font-medium pt-1">{item.node.reviewer}</h4>
              <div style={{ width: "25px", marginLeft: "5px" }}>
                <ReviewSourceComponent source={item.node.source} />
              </div>
            </div>

            <span className="text-gray-600 text-sm  d-block">
              Posted <TimeAgo date={item.node.date} />
            </span>
          </div>
          <div>
            <Stars size={20} fillColor={ReturnSourceFillColor(item.node.source)} />
          </div>
        </div>
      </div>

      <p className="text-gray-500 leading-medium text-base mb-0 p-4 reviews-styles">
        <span
          dangerouslySetInnerHTML={{
            __html: item.node.reviewBody.reviewBody,
          }}
        />
      </p>
      {item.node.reviewImages ? (
        <div className="grid gap-3 grid-cols-2 md:grid-cols-4 lg:grid-cols-4 px-4">
          {item.node.reviewImages.map((item, index) => (
            <GatsbyImage
              className="rounded mr-2 object-contain   w-full"
              objectFit={"contain"}
              image={getImage(item)}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

const ReviewsContent = ({ queryData }) => {
  return (
    <div>
      {queryData.allContentfulTestimonial.edges.map((item, i) => (
        <ReviewForInModal item={item} key={i} itemKey={i} />
      ))}
    </div>
  );
};

//page can be linked to directly or via modal using ModalRoutingContext
const ReviewsTemplate = (props) => {
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => {
        if (modal) {
          const ModalView = lazy(() => import("./reviews/modal"));
          return (
            <div className="h-full overflow-y-scroll gvd-main-bg">
              <div className="bg-white p-4 pt-3 py-5 sticky top-0 z-20 flex items-center justify-between shadow-md rounded-2xl">
                <div className="flex items-center">
                  <div className="logo-container mr-4" style={{ marginBottom: "-12px", width: "140px" }}>
                    <Logo horizontal={true} color="dark" />
                  </div>

                  <div>
                    <div className="flex items-center">
                      <Stars size={19} />
                      <p className="mb-0">
                        <span className="bold ml-1">{REVIEW_AVG}/5</span>
                      </p>
                    </div>
                    <span className="text-sm italic text-gray-700 d-block -mt-2">
                      Based on {TOTAL_REVIEW_COUNT} reviews
                    </span>
                  </div>
                </div>

                <Link
                  className="cursor-pointer d-block border-radius gvd-main-bg p-2 f  m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                  to={closeTo}
                  state={{
                    noScroll: true,
                  }}
                >
                  <X color={"#333"} strokeWidth={"3"} className="d-block" />
                </Link>
              </div>
              <Suspense
                fallback={
                  <div className="flex w-full h-full justify-center">
                    <span className="spinner -mt-20" />
                  </div>
                }
              >
                <ModalView {...{ closeTo, ...props }} />
              </Suspense>
            </div>
          );
        }
        return (
          <Layout pageProps={props}>
            <SEO title={`Bay Area Siding Company Reviews`} />

            <div className="mx-auto  px-4 sm:px-6 lg:px-8 py-12  max-w-7xl flex flex-wrap">
              <div className="w-full md:w-2/3 md:pr-8 pb-4">
                <h1 className="font-bold text-2xl text-center md:text-left pb-2  md:pb-0 md:text-3xl mb-1">
                  {/* {props.data.allContentfulTestimonialCategory.edges.map(
                    (item, i) => {
                      return (i > 0 ? ', ' : '') + item.node.name
                    }
                  )} */}
                  Bay Area Siding Company Reviews
                </h1>
                {/* <p className="text-base md:text-lg text-gray-600">
                  {props.data.allContentfulTestimonial.edges.length} reviews
                </p> */}
                <div className=" pb-4">
                  <HeroRatingStars classes="text-center md:text-left"  centerMobile={true}/>
                </div>
                <div className="rounded-2xl overflow-hidden shadow-lg bg-white p-2">
                  <ReviewsContent queryData={props.data} />
                </div>
              </div>
              <div className="w-full md:w-1/3 space-y-4 md:space-y-6">
                <WhyUs />{" "}
              </div>
            </div>
          </Layout>
        );
      }}
    </ModalRoutingContext.Consumer>
  );
};

export default ReviewsTemplate;

export const pageQuery = graphql`
  query reviewsCategory($slugArray: [String!]) {
    allContentfulTestimonialCategory(filter: { slug: { in: $slugArray } }) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    contentfulTestimonialCategory(slug: { in: $slugArray }) {
      id
      name
      slug
    }
    allContentfulTestimonial(
      sort: { fields: date, order: DESC }
      filter: { category: { elemMatch: { slug: { in: $slugArray } } } }
      limit: 100
    ) {
      edges {
        node {
          id
          reviewTitle
          featuredReview
          date
          reviewer
          reviewImages {
            gatsbyImageData(width: 200)
          }
          userPhoto {
            gatsbyImageData(width: 65)
          }
          reviewBody {
            id
            reviewBody
          }
          category {
            id
          }
          source
        }
      }
    }
  }
`;
